import React, { useState } from 'react';
import { ManagerShape } from 'utils/types';
import SmoothCollapse from 'react-smooth-collapse';

const ManagerCard = ({ manager }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="mb-5">
            <div id="module" className="row manager-card">
                <div
                    className="manager-card__image img-fluid col-xl-6 p-0"
                    style={{ backgroundImage: `url(${manager.image})` }}
                />
                <div className="manager-card__text col-xl-6 pl-5">
                    <SmoothCollapse expanded={isOpen} collapsedHeight="343px">
                        <h3>{manager.name}</h3>
                        <div className="manager-card__text__subtitle small-caps">
                            {manager.role}
                        </div>
                        <div
                            className={`manager-card__text__description${
                                isOpen ? '--open' : '--closed'
                            }`}
                            id="collapseExample"
                            dangerouslySetInnerHTML={{
                                __html: manager.description,
                            }}
                        />
                    </SmoothCollapse>
                </div>
            </div>
            <button
                className="btn btn-toggle-desc"
                onClick={() => setIsOpen(!isOpen)}
            >
                {isOpen ? 'Sulge' : 'Loe veel'}
            </button>
        </div>
    );
};

ManagerCard.propTypes = {
    manager: ManagerShape.isRequired,
};

export default ManagerCard;
