import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverBody } from 'reactstrap';
import Select from 'react-select';
import { connect, useDispatch, useSelector } from 'react-redux';
import range from 'lodash.range';
import queryString from 'query-string';
import Sidebar from 'react-sidebar';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import Url from 'url-parse';
import MenuCategories from './MenuCategories';
import MenuItems from './MenuItems';
import TermsModal from './TermsModal';
import ChangeShowModal from './ChangeShowModal';
import { interpolate, ngettext, gettext, pgettext } from '../../utils/text';
import {
    fetchCategories,
    selectIsProductsStartLoad,
    selectIsCategoriesStartLoad,
    fetchProductsForCategory,
} from '../../ducks/shop.ts';
import DropdownIndicator from '../DropdownIndicator';
import { getFormPropTypes } from '../../utils/types/forms';
import {
    getIntermissionOrderShows,
    selectors as showsSelectors,
} from '../../ducks/shows';
import {
    loadCart,
    removeFromCart,
    checkOutCart,
    selectors as cartSelectors,
} from '../../ducks/cart';
import Loading from '../Loading';
import { ErrorComponent } from '../../forms/fields/FormField';

// dock the sidebar if the page width is above 768px
const mql = window.matchMedia(`(min-width: 768px)`);

const getInitTitle = () => gettext('Intermission order');

/* global INTERMISSION_PAGE_DESCRIPTION CATERING_PHONE_NUMBER */
const RestaurantMenu = ({
    values,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
}) => {
    const [docked, setDocked] = useState(mql.matches);
    const [open, setOpen] = useState(true);
    const [containerHeight, setContainerHeight] = useState(150);
    const [headerTitle, setHeaderTitle] = useState(getInitTitle());
    const [popoverCartOpen, setPopoverCartOpen] = useState(false);
    const [popoverTermsOpen, setPopoverTermsOpen] = useState(false);
    const [popoverChangeShowOpen, setPopoverChangeShowOpen] = useState(false);
    const [showOption, setShowOption] = useState(null);
    const [productsToRemove, setProductsToRemove] = useState([]);
    const dispatch = useDispatch();

    const leftMenuRef = useRef();
    const rightMenuRef = useRef();
    const headerRef = useRef();

    const query = queryString.parse(window.location.search, {});
    const showId = parseInt(query.show, 10);
    const categoryId = parseInt(query.category, 10);
    const [activeCategory, setActiveCategory] = useState(categoryId || null);
    const [selectedShowId, setSelectedShowId] = useState(showId || null);

    const onSetHeaderTitle = title =>
        !docked ? setHeaderTitle(title) : setHeaderTitle(getInitTitle());

    const mediaQueryChanged = () => {
        setDocked(mql.matches);
        setOpen(false);
        onSetHeaderTitle(getInitTitle());
    };

    const onSetContainerHeight = () => {
        if (rightMenuRef.current && leftMenuRef.current) {
            const size = Math.max(
                rightMenuRef.current.clientHeight,
                leftMenuRef.current.clientHeight,
                60,
            );
            setContainerHeight(size);
        }
    };

    useEffect(() => {
        dispatch(fetchCategories(selectedShowId, activeCategory));
        dispatch(getIntermissionOrderShows(null));
        dispatch(loadCart());
    }, [dispatch, selectedShowId]);

    const isCategoriesStartLoad = useSelector(selectIsCategoriesStartLoad);
    const isProductsStartLoad = useSelector(selectIsProductsStartLoad);

    useEffect(() => {
        const timerId = setTimeout(onSetContainerHeight, 1000);

        return () => {
            clearTimeout(timerId);
        };
    }, [isCategoriesStartLoad, isProductsStartLoad]);

    useEffect(() => {
        mql.addListener(mediaQueryChanged);
        window.addEventListener('resize', onSetContainerHeight);
        window.addEventListener('load', onSetContainerHeight);

        return () => {
            mql.removeListener(mediaQueryChanged);
            window.removeEventListener('resize', onSetContainerHeight);
            window.removeEventListener('load', onSetContainerHeight);
        };
    });

    const toggleOpen = ev => {
        setOpen(true);
        onSetHeaderTitle(getInitTitle());
        if (ev) {
            ev.preventDefault();
        }
    };

    const toggleClose = ev => {
        setOpen(false);
        if (ev) {
            ev.preventDefault();
        }
    };

    const backBtn = (
        <div>
            {!docked && (
                <div className="menu__header__btn">
                    <button
                        type="submit"
                        className="btn btn-primary btn--arrow btn--back"
                        onClick={() => toggleOpen()}
                    />
                </div>
            )}
        </div>
    );
    const replaceQueryParameter = (key, value) => {
        const url = new Url(window.location.href, true);
        url.query[key] = value;
        window.history.replaceState(null, null, url);
    };

    const changeCategory = id => {
        setActiveCategory(id);
        replaceQueryParameter('category', id);
    };

    const sidebar = (
        <MenuCategories
            ref={leftMenuRef}
            headerRef={headerRef}
            isOpen={open}
            isDocked={docked}
            closeMenu={() => toggleClose()}
            setHeaderTitle={onSetHeaderTitle}
            activeCategory={activeCategory}
            setActiveCategory={changeCategory}
            selectedShow={selectedShowId}
        />
    );

    const sidebarProps = {
        sidebar,
        docked,
        open,
        onSetOpen: setOpen,
        styles: {
            root: {
                position: 'relative',
                display: 'flex',
                // if categories are being loaded set height for the sidebar 152 pixels
                height: isCategoriesStartLoad ? 152 : containerHeight,
            },
            overlay: {
                display: 'none',
            },
            sidebar: {
                overflowY: 'visible',
                position: 'relative',
                margin: 0,
                transform: 'none',
                boxShadow: 'none',
                backgroundColor: 'white',
            },
            content: {
                position: 'absolute',
                // if categories are being loaded set overflow to none
                overflow: isCategoriesStartLoad ? 'hidden' : 'auto',
                transform: 'none',
                minHeight: 10000,
            },
        },
    };

    const togglePopover = () => setPopoverCartOpen(!popoverCartOpen);
    const toggleTermsPopover = () => setPopoverTermsOpen(!popoverTermsOpen);
    const toggleChangeShowPopover = () =>
        setPopoverChangeShowOpen(!popoverChangeShowOpen);

    const getSelectCustomStyles = () => ({
        control: styles => ({
            ...styles,
            border: '0 none',
            opacity: '0.9',
        }),
        menu: styles => ({
            ...styles,
            marginTop: 0,
        }),
    });

    const cart = useSelector(cartSelectors.cart);
    const shows = useSelector(showsSelectors.intermissionShows);

    useEffect(() => {
        const selectedShow = shows.find(o => o.value === selectedShowId);
        if (selectedShow) {
            setFieldValue('show', selectedShow);
        }
    }, [shows, selectedShowId]);

    useEffect(() => {
        setFieldValue('line_items', cart ? cart.num_items : 0);
    }, [cart]);

    const changeSelectedShow = show => {
        if (show) {
            setSelectedShowId(show.value);
            setFieldValue('show', show);

            // Remove the products from cart that are not specific to the selected staging
            if (productsToRemove.length > 0) {
                productsToRemove.forEach(productId => {
                    dispatch(
                        removeFromCart({
                            product: productId,
                        }),
                    );
                });

                setProductsToRemove([]);
                toggleChangeShowPopover();
            }
            replaceQueryParameter('show', show.value);
            fetchProductsForCategory(activeCategory, show.value, true);
        }
    };

    const handleShowChange = show => {
        if (cart && cart.lines.length > 0) {
            // Check if the cart has products that are specific to previously selected staging
            const productList = cart.lines
                .filter(
                    line =>
                        line.product.stagings.length !== 0 &&
                        !line.product.stagings.includes(show.stagingId),
                )
                .map(line => line.product.id);

            // Show confirmation model only if the cart has staging specific products
            if (productList && productList.length !== 0) {
                setProductsToRemove(productList);
                setShowOption(show);
                toggleChangeShowPopover();
            } else {
                changeSelectedShow(show);
            }
        } else {
            changeSelectedShow(show);
        }
    };

    let orderTypes = [
        {
            value: 1,
            label: gettext('An hour before event'),
        },
        {
            value: 2,
            label: gettext('During the break'),
        },
    ];

    const hasRestaurantProduct =
        cart &&
        cart.lines &&
        cart.lines.some(l => l.product.is_restaurant_only);
    if (hasRestaurantProduct) {
        orderTypes = [
            {
                value: 3,
                label: gettext('Two hours before event'),
            },
        ];
    }

    useEffect(() => {
        // if old `order_type` doesn`t exists in new `orderTypes`, reset the value
        if (!orderTypes.find(v => v.value === values.order_type)) {
            setFieldValue('order_type', undefined, false);
        }
    }, [cart]);

    return (
        <form onSubmit={handleSubmit} className="checkout row w-100">
            <div className="col-sm-12 col-lg-7 px-0 pr-lg-5">
                <div className="menu__header row" ref={headerRef}>
                    <div className="menu__header__content mt-2 col-lg-6 col-xs-12">
                        {backBtn}
                        <p className="menu__header__caption">{headerTitle}</p>
                        <p className="menu__header__description">
                            {INTERMISSION_PAGE_DESCRIPTION !== 'None'
                                ? INTERMISSION_PAGE_DESCRIPTION
                                : gettext(
                                      'We accept orders placed via our website no later than 24 h prior to the beginning of the performance. After that, you can only place orders in the bars and cafes of the theatre an hour before the beginning of the performance or concert.',
                                  )}
                        </p>
                    </div>
                    <div className="menu__header__selectors col-lg-6 col-xs-12">
                        <Field
                            name="show"
                            component={Select}
                            value={values.show}
                            placeholder={gettext('Select a show')}
                            components={{ DropdownIndicator }}
                            options={shows}
                            className={
                                errors.show && touched.show
                                    ? 'react-select-error'
                                    : 'react-select'
                            }
                            classNamePrefix="react-select"
                            onChange={option => {
                                handleShowChange(option);
                            }}
                            styles={getSelectCustomStyles()}
                            onBlur={() => setFieldTouched('show', true)}
                        />
                        <ChangeShowModal
                            isOpen={popoverChangeShowOpen}
                            toggleModal={toggleChangeShowPopover}
                            changeSelectedShow={changeSelectedShow}
                            show={showOption}
                        />
                        <ErrorComponent
                            name="show"
                            className="px-3 py-1 font-weight-bold"
                        />
                        <Field
                            name="people_count"
                            component={Select}
                            components={{ DropdownIndicator }}
                            placeholder={gettext('Choose the number of people')}
                            options={range(1, 20).map(count => ({
                                value: count,
                                label: `${count} ${interpolate(
                                    ngettext('person', 'persons', count),
                                    { count },
                                )}`,
                            }))}
                            className={
                                errors.people_count && touched.people_count
                                    ? 'react-select-error'
                                    : 'react-select'
                            }
                            classNamePrefix="react-select"
                            styles={getSelectCustomStyles()}
                            onChange={option =>
                                setFieldValue('people_count', option.value)
                            }
                            onBlur={() => setFieldTouched('people_count', true)}
                        />
                        <ErrorComponent
                            name="people_count"
                            className="px-3 py-1 font-weight-bold"
                        />
                    </div>
                </div>
                <Sidebar {...sidebarProps}>
                    {isProductsStartLoad ? (
                        <Loading />
                    ) : (
                        <MenuItems
                            ref={rightMenuRef}
                            activeCategory={activeCategory}
                            selectedShow={selectedShowId}
                        />
                    )}
                </Sidebar>
            </div>
            <div className="col-sm-12 col-lg-5 px-0">
                <div className="checkout__form">
                    <h4>{gettext('Summary')}</h4>
                    <h5>{values.show ? values.show.label : ''}</h5>
                    {cart && cart.lines.length > 0 && (
                        <div className="checkout__form__products">
                            {cart.lines.map(line => (
                                <div
                                    key={line.product.id}
                                    className="checkout__form__products__product"
                                >
                                    <div className="checkout__form__products__product__name">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: line.product.name,
                                            }}
                                        />
                                    </div>
                                    <span>
                                        {line.product.price.gross}&euro;
                                    </span>
                                    <span>{line.quantity}</span>
                                    <span className="checkout__form__products__product__total">
                                        {line.product.price.gross *
                                            line.quantity}
                                        &euro;
                                    </span>
                                    <span // eslint-disable-line jsx-a11y/click-events-have-key-events
                                        className="checkout__form__products__product__remove"
                                        onClick={() =>
                                            dispatch(
                                                removeFromCart({
                                                    product: line.product.id,
                                                }),
                                            )
                                        }
                                    >
                                        X
                                    </span>
                                </div>
                            ))}
                        </div>
                    )}
                    <ErrorComponent name="line_items" />
                    <div className="checkout__form__total">
                        <h4>{gettext('Total price')}</h4>
                        <div className="ml-auto">
                            <span className="checkout__form__total__price mr-2">
                                {cart ? cart.total.gross : 0}&nbsp;&euro;
                            </span>
                            <span
                                className="checkout__form__question-circle"
                                id="orderPopover"
                            >
                                ?
                            </span>
                            <Popover
                                placement="bottom"
                                isOpen={popoverCartOpen}
                                target="orderPopover"
                                toggle={togglePopover}
                                trigger="hover"
                            >
                                <PopoverBody>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: gettext(
                                                'Please send orders for more than 20 people at least ' +
                                                    '3 workdays beforehand to e-mail: <a href="mailto:catering@opera.ee"> ' +
                                                    'catering@opera.ee</a>. Food for an intermission can be also ordered on ' +
                                                    'the place in cafes. An order will take approximately 24 hours before show.',
                                            ),
                                        }}
                                    />
                                </PopoverBody>
                            </Popover>
                        </div>
                    </div>

                    <Field
                        name="full_name"
                        type="text"
                        className="form-control"
                        placeholder={gettext('Firstname and lastname')}
                        maxLength={64}
                    />
                    <span className="text-danger">*</span>
                    <ErrorComponent name="full_name" />
                    <Field
                        label="Company name"
                        type="text"
                        name="company_name"
                        className="form-control"
                        placeholder={pgettext(
                            'intermission-form-company-field',
                            'Company',
                        )}
                        maxLength={64}
                    />
                    <ErrorComponent name="company_name" />
                    <Field
                        name="phone"
                        type="text"
                        className="form-control"
                        placeholder={gettext('Phone')}
                        maxLength={64}
                    />
                    <span className="text-danger">*</span>
                    <ErrorComponent name="phone" />
                    <Field
                        name="address"
                        type="text"
                        className="form-control"
                        placeholder={gettext('Address')}
                        maxLength={255}
                    />
                    <ErrorComponent name="address" />
                    <Field
                        id="email"
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder={gettext('E-mail')}
                        maxLength={128}
                    />
                    <span className="text-danger">*</span>
                    <ErrorComponent name="email" />
                    <div>
                        <small id="passwordHelpInline" className="text-muted">
                            {gettext(
                                'Please be sure that you have entered e-mail correctly!',
                            )}
                        </small>
                    </div>
                    <Field
                        name="order_type"
                        component={Select}
                        components={{ DropdownIndicator }}
                        placeholder={gettext('When do you want?')}
                        options={orderTypes}
                        // `undefined` value doesn`t work to reset value, but `null` does
                        value={
                            orderTypes.find(
                                v => v.value === values.order_type,
                            ) || null
                        }
                        className="react-select"
                        classNamePrefix="react-select"
                        onChange={option =>
                            setFieldValue('order_type', option.value)
                        }
                        onBlur={() => setFieldTouched('order_type', true)}
                    />
                    <span className="text-danger">*</span>
                    <ErrorComponent name="order_type" className="mt-2" />
                    <Field
                        name="comment"
                        component="textarea"
                        className="form-control"
                        placeholder={gettext('Comments')}
                        maxLength={500}
                    />
                    <ErrorComponent name="comment" />
                    <div className="text-danger mt-1">
                        * <small>{gettext('Mandatory fields.')}</small>
                    </div>
                    <p
                        className="checkout__form__notice"
                        dangerouslySetInnerHTML={{
                            __html:
                                gettext(
                                    '<span>Please be sure that before pressing ' +
                                        '"Back to the shop" you have received confirmation email. In the case if you haven\'t ' +
                                        'received e-mail, please contact by </span> ' +
                                        '<a href="mailto:catering@opera.ee">catering@opera.ee</a> ' +
                                        '<span>or E-R 10-16 by phone ',
                                ) +
                                CATERING_PHONE_NUMBER +
                                gettext(' to refill order again.</span>'),
                        }}
                    />
                </div>
                <h4>{gettext('Choose payment method')}</h4>
                <ErrorComponent name="payment_provider" />
                <div className="checkout__payment">
                    <div className="checkout__payment__links">
                        {DJ_CONST.ENABLED_PAYMENT_PROVIDERS.includes(
                            'MontonioBank',
                        ) && (
                            <div className="form-check">
                                <label className="form-check-label radio">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="payment_provider"
                                        value="MontonioBank"
                                        onChange={handleChange}
                                    />
                                    <span>
                                        <div className="form-check__payment-provider">
                                            {gettext('Pay with your bank')}
                                            <img
                                                src={`${DJ_CONST.STATIC_URL}images/payment_logos/montonio.png`}
                                                alt="Montonio"
                                            />
                                        </div>
                                    </span>
                                </label>
                            </div>
                        )}
                        {DJ_CONST.ENABLED_PAYMENT_PROVIDERS.includes(
                            'MontonioCard',
                        ) && (
                            <div className="form-check">
                                <label className="form-check-label radio">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="payment_provider"
                                        value="MontonioCard"
                                        onChange={handleChange}
                                    />
                                    <span>
                                        <div className="form-check__payment-provider">
                                            {gettext('Card payment')}
                                            <img
                                                src={`${DJ_CONST.STATIC_URL}images/payment_logos/visa-mc.png`}
                                                alt="Creditcard"
                                            />
                                        </div>
                                    </span>
                                </label>
                            </div>
                        )}
                        {DJ_CONST.ENABLED_PAYMENT_PROVIDERS.includes(
                            'swedbank',
                        ) && (
                            <div className="form-check">
                                <label className="form-check-label radio">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="payment_provider"
                                        value="swedbank"
                                        onChange={handleChange}
                                    />
                                    <span>
                                        <div className="form-check__payment-provider">
                                            {gettext('Swedbank')}
                                            <img
                                                src={`${DJ_CONST.STATIC_URL}images/payment_logos/swedbank.png`}
                                                alt="Swedbank"
                                            />
                                        </div>
                                    </span>
                                </label>
                            </div>
                        )}
                        {DJ_CONST.ENABLED_PAYMENT_PROVIDERS.includes(
                            'nordea',
                        ) && (
                            <div className="form-check">
                                <label className="form-check-label radio">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="payment_provider"
                                        value="nordea"
                                        onChange={handleChange}
                                    />
                                    <span>
                                        <div className="form-check__payment-provider">
                                            {gettext('Luminor')}
                                            <img
                                                src={`${DJ_CONST.STATIC_URL}images/payment_logos/luminor.png`}
                                                alt="Luminor"
                                            />
                                        </div>
                                    </span>
                                </label>
                            </div>
                        )}
                        {DJ_CONST.ENABLED_PAYMENT_PROVIDERS.includes('seb') && (
                            <div className="form-check">
                                <label className="form-check-label radio">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="payment_provider"
                                        value="seb"
                                        onChange={handleChange}
                                    />
                                    <span>
                                        <div className="form-check__payment-provider">
                                            {gettext('SEB')}
                                            <img
                                                src={`${DJ_CONST.STATIC_URL}images/payment_logos/seb.png`}
                                                alt="Seb"
                                            />
                                        </div>
                                    </span>
                                </label>
                            </div>
                        )}
                        {DJ_CONST.ENABLED_PAYMENT_PROVIDERS.includes('lhv') && (
                            <div className="form-check">
                                <label className="form-check-label radio">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="payment_provider"
                                        value="lhv"
                                        onChange={handleChange}
                                    />
                                    <span>
                                        <div className="form-check__payment-provider">
                                            {gettext('LHV')}
                                            <img
                                                src={`${DJ_CONST.STATIC_URL}images/payment_logos/lhv.png`}
                                                alt="LHV"
                                            />
                                        </div>
                                    </span>
                                </label>
                            </div>
                        )}
                        {DJ_CONST.ENABLED_PAYMENT_PROVIDERS.includes(
                            'EveryPay',
                        ) && (
                            <div className="form-check">
                                <label className="form-check-label radio">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="payment_provider"
                                        value="EveryPay"
                                        onChange={handleChange}
                                    />
                                    <span>
                                        <div className="form-check__payment-provider">
                                            {gettext('Card payment')}
                                            <img
                                                src={`${DJ_CONST.STATIC_URL}images/payment_logos/visa-mc.png`}
                                                alt="Visa"
                                            />
                                        </div>
                                    </span>
                                </label>
                            </div>
                        )}
                    </div>
                    <div className="row w-100 mx-0 checkout__payment__bottom">
                        <div className="col-md-6 col-sm-12 w-100 my-5 my-md-0 form-check">
                            <input
                                name="terms"
                                className="form-check-input checkbox"
                                type="checkbox"
                                id="terms-checkbox"
                                checked={values.terms}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <label
                                className="form-check-label checkout__terms-label"
                                htmlFor="terms-checkbox"
                            >
                                {gettext('I have read and agree with')}
                                <span
                                    className="checkout__terms-modal-label link text-danger"
                                    onClick={toggleTermsPopover}
                                    onKeyDown={toggleTermsPopover}
                                >
                                    {gettext(' additional order information')}
                                </span>
                            </label>

                            <TermsModal
                                isOpen={popoverTermsOpen}
                                toggleModal={toggleTermsPopover}
                            />

                            <ErrorComponent name="terms" className="mt-2" />
                        </div>
                        <div className="col-md-6 col-xs-12 pr-md-0 checkout__payment__bottom__button">
                            <button
                                className="btn btn-lg btn-primary btn--arrow"
                                type="submit"
                            >
                                {gettext('Pay')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const RestaurantForm = withFormik({
    mapPropsToValues: () => ({
        company_name: '',
        comment: '',
        email: '',
        full_name: '',
        language: DJ_CONST.languageCode,
        order_type: '',
        payment_provider: '',
        people_count: '',
        address: '',
        phone: '',
        terms: false,
        line_items: 0,
        show: '',
    }),
    validationSchema: Yup.object().shape({
        line_items: Yup.number()
            .min(1, gettext('Please select products.'))
            .required(),
        email: Yup.string()
            .email(gettext('Please enter a valid e-mail.'))
            .required(gettext('Please enter your e-mail.')),
        full_name: Yup.string().required(
            gettext('Please enter your full name.'),
        ),
        order_type: Yup.number().required(
            gettext('Please select an order type.'),
        ),
        payment_provider: Yup.string().required(
            gettext('Please select a payment provider.'),
        ),
        people_count: Yup.number().required(
            gettext('Please select number of people.'),
        ),
        phone: Yup.string().required(gettext('Phone is a required field.')),
        show: Yup.object().required(gettext('Please select a show.')),
        terms: Yup.boolean().oneOf(
            [true],
            gettext('You must accept terms and conditions.'),
        ),
        comment: Yup.string(),
        company_name: Yup.string(),
    }),
    handleSubmit: (values, { props, ...formik }) => {
        props.checkOutCart({ ...values, show: values.show.value }, formik);
    },
    displayName: 'RestaurantForm', // helps with React DevTools
})(RestaurantMenu);

const mapDispatchToProps = {
    checkOutCart,
};

const ConnectedRestaurantForm = connect(
    null,
    mapDispatchToProps,
)(RestaurantForm);

RestaurantMenu.propTypes = {
    checkOutCart: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    ...getFormPropTypes([
        'company_name',
        'comment',
        'email',
        'full_name',
        'language',
        'order_type',
        'payment_provider',
        'people_count',
        'address',
        'phone',
        'terms',
        'line_items',
    ]),
};

export default ConnectedRestaurantForm;
