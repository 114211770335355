import PropTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const ManagerShape = PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    role: PropTypes.string,
    id: PropTypes.number,
});
