import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prefer-stateless-function
class Actor extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        backgroundImage: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    };

    render() {
        const { name, role, url, backgroundImage } = this.props;

        return (
            <a href={url} className="link">
                <div
                    className="__actor"
                    style={{
                        backgroundImage,
                    }}
                >
                    <h4>{name}</h4>
                    <div className="__role">{role}</div>
                </div>
            </a>
        );
    }
}

export default Actor;
