import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'tg-modal';
import { gettext } from '../../utils/text';

const ChangeShowModal = ({ isOpen, toggleModal, changeSelectedShow, show }) => {
    return (
        <>
            <Modal isOpen={isOpen} autoWrap onCancel={toggleModal}>
                <p>
                    {gettext(
                        'If you are changing the show then the items only available for the previous ' +
                            'show will be removed from cart. Do you wish to change the show?\n',
                    )}
                </p>

                <button
                    className="btn btn-secondary cancel-button"
                    aria-label="No"
                    type="button"
                    onClick={() => toggleModal()}
                >
                    {gettext('No')}
                </button>
                <button
                    className="btn btn-primary confirm-button"
                    aria-label="Yes"
                    type="button"
                    onClick={() => {
                        changeSelectedShow(show);
                    }}
                >
                    {gettext('Yes')}
                </button>
            </Modal>
        </>
    );
};

ChangeShowModal.propTypes = {
    toggleModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    changeSelectedShow: PropTypes.func.isRequired,
};

export default ChangeShowModal;
