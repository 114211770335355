import api from '../utils/router.ts';

const SET_CART = 'CART/SET_CART';

const initialState = {
    cart: null,
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CART:
            return {
                ...state,
                cart: action.cart,
            };
        default:
            return state;
    }
}

const setCart = cart => ({ type: SET_CART, cart });

export const addToCart = data => dispatch => {
    api.addToCart.post(null, data).then(cart => {
        dispatch(setCart(cart));
    });
};

export const checkOutCart = (data, formik) => {
    api.checkoutCart.post(null, data).then(
        response => {
            window.location.href = response.payment_url;
        },
        error => {
            error.errors.map(field =>
                formik.setFieldError(field.fieldName, field.errors[0]),
            );
        },
    );
};

export const removeFromCart = data => dispatch => {
    api.removeFromCart.post(null, data).then(cart => {
        dispatch(setCart(cart));
    });
};

export const loadCart = () => dispatch => {
    api.loadCart.fetch().then(carts => {
        dispatch(setCart(carts));
    });
};

export const selectors = {
    cart: state => state.cart.cart,
};
