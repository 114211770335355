import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'tg-modal';
import { gettext, pgettext } from '../../utils/text';

const TermsModal = ({ isOpen, toggleModal }) => {
    return (
        <>
            <Modal
                isOpen={isOpen}
                autoWrap
                title={gettext('Terms and conditions of orders')}
                onCancel={toggleModal}
            >
                <div
                    dangerouslySetInnerHTML={{
                        __html: pgettext(
                            'intermission-order-terms',
                            '<ol>' +
                                '<li>To place an order, you need to have tickets for a performance by the day of placing the order.</li>' +
                                '<li>We accept orders no later than <b>24 hours before</b> before the performance.</li>' +
                                '<li>Orders for <b>more than 20 people</b> should be sent no later than <b>3 working days before</b> the performance to the email address <a href="mailto:catering@opera.ee">catering@opera.ee</a></li>' +
                                '<li>The order is deemed to be accepted from the moment of receiving the payment.</li>' +
                                '<li>Orders are processed in the order in which they are received.</li>' +
                                '<li>Once the order has been accepted by the theatre, no changes can be made to the order (change of date, products, etc.). ' +
                                'The theatre will not refund the fee paid for an accepted order if the customer has made a mistake when placing the order (wrong date, wrong products, etc.).</li>' +
                                '<li>Orders are served on-site at the theatre either before the performance or during the intermission. Orders are not available for takeaway.</li>' +
                                '<li>The order for a performance in the theatre hall is usually served to the orderer (by name) in the Blue Hall. ' +
                                'The catering service of the Estonian National Opera reserves the right to change the location of the order if necessary.</li>' +
                                '</ol>',
                        ),
                    }}
                />
            </Modal>
        </>
    );
};

TermsModal.propTypes = {
    toggleModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

export default TermsModal;
