import React from 'react';
import classNames from 'classnames';

import { format } from '../../utils/date';
import { gettext } from '../../utils/text';
import { ShowShape } from './types';

const displayPrice = price => {
    if (price % 1 === 0) {
        // has no numbers after floating point
        return `${price.toFixed(0)} €`;
    }
    return `${price.toFixed(2)} €`;
};

const numberSorter = (a, b) => {
    if (a > b) {
        return 1;
    }
    if (b > a) {
        return -1;
    }
    return 0;
};

const SingleShow = ({ show }) => {
    const { date, hours } = show.time;
    const prices = [];
    let priceStr = '';
    let remarkBlock = '';
    let showStatusStr = '';
    let showStatus = '';
    if (show.special_prices.length) {
        for (let i = 0; i < show.special_prices.length; i++) {
            const p = show.special_prices[i].price;
            prices.push(parseFloat(p));
        }
        prices.sort(numberSorter);
        priceStr = prices.map(p => displayPrice(p)).join(' / ');
    } else if (show.price) {
        const { green_price, yellow_price, red_price, blue_price } = show.price; // eslint-disable-line camelcase
        prices.push(parseFloat(green_price));
        prices.push(parseFloat(yellow_price));
        prices.push(parseFloat(red_price));
        prices.push(parseFloat(blue_price));
        prices.sort(numberSorter);
        priceStr = `${displayPrice(prices[0])} - ${displayPrice(
            prices[prices.length - 1],
        )}`;
    }

    if (show.staging.guest_staging) {
        remarkBlock = (
            <div
                className={classNames('__guest-staging', DJ_CONST.languageCode)}
            >
                {gettext('Guest show')}
            </div>
        );
    } else if (show.premiere) {
        remarkBlock = (
            <div className={classNames('__premiere', DJ_CONST.languageCode)}>
                {gettext('Premiere')}
            </div>
        );
    } else if (show.excursion) {
        remarkBlock = (
            <div className={classNames('__remark', DJ_CONST.languageCode)}>
                + {gettext('Excursion')}
            </div>
        );
    }

    if (show.cancelled) {
        showStatusStr = show.cancelled;
        showStatus = gettext('Cancelled');
    } else if (show.sold_out) {
        showStatusStr = show.sold_out;
        showStatus = gettext('Sold out');
    }

    return (
        <a
            href={show.url}
            className={classNames('__show col-sm-12 col-lg-4', {
                '__sold-out': showStatusStr,
            })}
        >
            <div
                className="__preview"
                style={{ backgroundImage: `url(${show.staging.image})` }}
            />
            <div className="__details">
                <div className="__date">
                    <span className="__weekday">{format(date, 'EEEEEE')}</span>
                    <span className="__day">{format(date, 'dd')}</span>
                    <span className="__x">
                        {format(date, 'MMM')}{' '}
                        <span className="__year">{date.getFullYear()}</span>
                        <br />
                        {hours}
                    </span>
                </div>
                <h3 className="__title">{show.staging.name}</h3>
                <div className="__meta">
                    <span className="__category">
                        {show.staging.staging_note ? (
                            <span>{show.staging.staging_note}</span>
                        ) : (
                            show.staging.types_of_staging.map(type => (
                                <span>{type}</span>
                            ))
                        )}
                    </span>
                    <span className="__price">{priceStr}</span>
                </div>
                <div className="__meta">
                    <span className="__places">
                        {showStatus}
                        {show.last_tickets ? gettext('Last tickets') : ''}
                        <section>
                            {show.location ? show.location.name : ''}
                        </section>
                    </span>
                </div>
            </div>
            {remarkBlock}
        </a>
    );
};

SingleShow.propTypes = {
    show: ShowShape.isRequired,
};

export default SingleShow;
