import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { ImageOrderShape } from '../../utils/types';

const GallerySlider = ({ images, initialSlide }) => {
    const settings = {
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide,
    };

    const imgElements = images.map((image, idx) => (
        <div className="img-wrapper" key={`order-${image.file.pk}`}>
            <img alt={`gallery item number ${idx}`} src={image.file.file} />
        </div>
    ));

    return <Slider {...settings}>{imgElements}</Slider>;
};

GallerySlider.propTypes = {
    images: PropTypes.arrayOf(ImageOrderShape).isRequired,
    initialSlide: PropTypes.number.isRequired,
};

export default GallerySlider;
