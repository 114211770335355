import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import Actor from './Actor';
import { FrontImageSlider } from '../../utils/types/front-image-slider';

class ActorsCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            containerWidth: null,
            actors: null,
        };
    }

    componentDidMount() {
        // fetch actors data
        this.getData();
        // set up resizing handler and fetch initial dimensions
        window.addEventListener('resize', () => this.onresize());
        this.onresize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.onresize());
    }

    onresize() {
        this.setState({
            containerWidth: document.querySelector('.container__actors')
                .offsetWidth,
        });
    }

    // eslint-disable-next-line class-methods-use-this
    getData() {
        // this fn will make an API call and setState(actors)
        this.setState({ actors: this.props.artists });
    }

    transformData(startIndex) {
        // this fn will make an API call
        let i = startIndex || 0;
        return this.state.actors.map(item => {
            i += 1;
            return <Actor key={i} {...item} />;
        });
    }

    render() {
        const { actors } = this.state;
        if (!actors || !actors.length) {
            return null;
        }
        const { containerWidth } = this.state;
        let innerClassName = '__actors';
        let result;
        let style;
        const width = actors.length * 360;
        if (containerWidth > width) {
            // no need for carousel if all can fit width
            result = this.transformData(actors);
            innerClassName = `${innerClassName} __centered`;
            style = { width };
        } else {
            const minElements = 6;
            const sliderSettings = {
                infinite: true,
                speed: 500,
                variableWidth: true,
                slidesToScroll: 1,
                centerMode: true,
                autoplay: true,
                autoplaySpeed: 2000,
            };
            result = this.transformData(actors);
            while (result.length < minElements + 1) {
                // fill the actors list so that it contains the minimum number of elements,
                // and one more to account for margins
                // since the carousel is infinite this makes no difference
                result = result.concat(this.transformData(actors.length + 1));
            }
            result = <Slider {...sliderSettings}>{result}</Slider>;
        }
        // eslint-disable-next-line consistent-return
        return (
            <div className={innerClassName} style={style}>
                {result}
            </div>
        );
    }
}

ActorsCarousel.propTypes = {
    artists: PropTypes.arrayOf(FrontImageSlider),
};

ActorsCarousel.defaultProps = {
    artists: [],
};

export default ActorsCarousel;
