import React, { useState } from 'react';
import Modal from 'tg-modal';

import { gettext } from '../../utils/text';
import GallerySlider from './GallerySlider';
import sort from './utils';

const BlogGalleryLink = ({ data }) => {
    const [open, setOpen] = useState(false);
    const translatedText = gettext('Open Gallery');
    const linkText = `${translatedText} (${data.images.length})`;

    const toggleOpen = ev => {
        setOpen(true);
        if (ev) {
            ev.preventDefault();
        }
    };

    const toggleClose = ev => {
        setOpen(false);
        if (ev) {
            ev.preventDefault();
        }
    };

    const images = data.images.sort(sort);

    return [
        <span
            onClick={() => toggleOpen()}
            onKeyDown={() => toggleOpen()}
            key="span"
        >
            <img
                alt="icon"
                src={`${DJ_CONST.STATIC_URL}images/elements/icon-image.png`}
                className="masthead__gallery-link__icon"
            />
            {linkText}
        </span>,
        <Modal
            isOpen={open}
            autoWrap={false}
            onCancel={() => toggleClose()}
            dialogClassName="gallery-modal"
            key="mdl"
        >
            <button
                className="tg-modal-close"
                aria-label="Close"
                type="button"
                onClick={() => toggleClose()}
            />
            <GallerySlider images={images} initialSlide={0} />
        </Modal>,
    ];
};

export default BlogGalleryLink;
