import { FetchResource as Resource } from '@tg-resources/fetch';

function getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (pair[0] === variable) {
            return pair[1];
        }
    }
    return false;
}

export default function loadMore(target) {
    const loadMoreContainer = target.parentElement;
    const contentBlock = loadMoreContainer.parentElement;

    const nextPage = target.dataset.page;

    let queryURL = `//${window.location.host}${window.location.pathname}?loadmore=1&page=${nextPage}`;
    const sortValue = getQueryVariable('sort');
    if (sortValue) {
        queryURL += `&sort=${sortValue}`;
    }

    const loadMoreResource = new Resource(queryURL, {
        mutateResponse: (rD, r) => r.text,
    });

    loadMoreResource.fetch().then(posts => {
        contentBlock.removeChild(loadMoreContainer);
        contentBlock.innerHTML += posts;
    });
}
