import PropTypes from 'prop-types';

export const OptionShape = PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    label: PropTypes.string.isRequired,
});

const StagingShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    types_of_staging: PropTypes.arrayOf(PropTypes.string).isRequired,
    image: PropTypes.string.isRequired,
    length: PropTypes.number.isRequired,
    tickets_link: PropTypes.string,
});

const LocationShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
});

const PriceShape = PropTypes.shape({
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
});

const RoleShape = PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    role_name: PropTypes.string.isRequired,
    bw_image: PropTypes.string.isRequired,
    visible_tag: PropTypes.string,
    url: PropTypes.string.isRequired,
    is_performer: PropTypes.bool.isRequired,
    is_replacement: PropTypes.bool.isRequired,
});

export const ShowShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    time: PropTypes.shape({
        date: PropTypes.instanceOf(Date).isRequired,
        hours: PropTypes.string.isRequired,
    }),
    staging: StagingShape.isRequired,
    location: LocationShape,
    people: PropTypes.arrayOf(RoleShape).isRequired,
    prices: PropTypes.arrayOf(PriceShape).isRequired,
    excursion: PropTypes.bool.isRequired,
    premiere: PropTypes.bool.isRequired,
    cancelled: PropTypes.bool.isRequired,
    sold_out: PropTypes.bool.isRequired,
    intermission_order: PropTypes.bool.isRequired,
    is_replacement: PropTypes.bool,
    ticket_link: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
});

export const UpcomingShowShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    time: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    location_name: PropTypes.string.isRequired,
    sold_out: PropTypes.bool.isRequired,
    cancelled: PropTypes.bool.isRequired,
});
