import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import api from '../utils/router';

type CategoryID = number;
type ProductID = number;
type ShowID = number;

type Category = {
    id: number;
    name: string;
    product_count: number;
};

type TaxedMoney = {
    formatted: {
        gross: string;
        net: string;
        tax: string;
    };
    gross: number;
    net: number;
    tax: number;
};

type Product = {
    id: ProductID;
    category_id: number;

    name: string;
    description: string;
    thumbnail: string;
    photo: string;

    price: TaxedMoney;
    currency: string;
};

// TS1336 does not allow to use a type alias in an index signature. This is why we use number here.
//  see https://github.com/microsoft/TypeScript/issues/24437
type CategoryMap = { [categoryId: number]: Category };
type ProductMap = { [productId: number]: Product };

// Array of CategoryIDs in the order they were returned from API
type CategoryIdList = Array<CategoryID>;

// Map of categoryId to an array of productIds in the order they were returned from API
type ProductsByCategory = {
    [categoryId: number]: {
        items: Array<ProductID>;
        cursorNext: string | null;
    };
};

type IntermissionShopState = {
    products: ProductMap;
    category: CategoryMap;

    categories: CategoryIdList;
    isCategoriesStartLoad: boolean;
    productsByCategory: ProductsByCategory;
    isProductsStartLoad: boolean;
};

const initialState: IntermissionShopState = {
    category: {},
    categories: [],
    isCategoriesStartLoad: true,

    products: {},
    productsByCategory: {},
    isProductsStartLoad: true,
};

type LoadedCategoriesAction = {
    type: 'intermission/loaded-categories';
    categories: Array<Category>;
};

type LoadedProductsAction = {
    type: 'intermission/loaded-products';
    categoryId: CategoryID;
    showId: ShowID;
    products: Array<Product>;
    cursorNext: string | null;
    reset: boolean;
};

type StartLoadProductsAction = {
    type: 'intermission/start-load-products';
};

type IntermissionAction =
    | LoadedCategoriesAction
    | LoadedProductsAction
    | StartLoadProductsAction;

const asCategoryMap = (items: Array<Category>) => {
    const res: CategoryMap = {};

    for (let category of items) {
        res[category.id] = category;
    }

    return res;
};

const asProductMap = (items: Array<Product>) => {
    const res: ProductMap = {};

    for (let product of items) {
        res[product.id] = product;
    }

    return res;
};

const mergeIds = (
    originalArr: Array<number>,
    newArr: Array<number>,
    reset: boolean = true,
): Array<number> => {
    if (reset) {
        return newArr;
    }
    newArr.forEach(item => {
        if (originalArr.indexOf(item) === -1) {
            originalArr.push(item);
        }
    });

    return originalArr;
};

export const shopReducer = (
    state = initialState,
    action: IntermissionAction,
): IntermissionShopState => {
    switch (action.type) {
        case 'intermission/loaded-categories':
            return {
                ...state,
                isCategoriesStartLoad: false,
                categories: action.categories.map(
                    (category: Category) => category.id,
                ),
                category: {
                    ...state.category,
                    ...asCategoryMap(action.categories),
                },
            };

        case 'intermission/start-load-products':
            return {
                ...state,
                isProductsStartLoad: true,
            };

        case 'intermission/loaded-products':
            return {
                ...state,
                isProductsStartLoad: false,
                productsByCategory: {
                    ...state.productsByCategory,

                    [action.categoryId]: {
                        cursorNext: action.cursorNext,
                        items: mergeIds(
                            state.productsByCategory[action.categoryId]
                                ?.items || [],
                            action.products.map(
                                (product: Product) => product.id,
                            ),
                        ),
                    },
                },

                products: {
                    ...state.products,
                    ...asProductMap(action.products),
                },
            };

        default:
            return state;
    }
};

export type AppState = { shop: IntermissionShopState };

export type AppThunk = ThunkAction<
    Promise<void>,
    AppState,
    undefined,
    AnyAction
>;

export type GetState = () => AppState;

const extractQueryParam = (uri: string, param: string): string | null => {
    const url = new URL(uri);

    return url.searchParams.get(param);
};

// FIXME: Error & loading states for categories and products fetching
//   Note: Implement when necessary (during UI implementation - EOT-124/EOT-126)

export const fetchProductsForCategory = (
    categoryId: CategoryID,
    showId: ShowID,
    reset = false,
): AppThunk => async (dispatch: any, getState: GetState) => {
    const state = getState();

    const initialCursor = state.shop.productsByCategory[categoryId]?.cursorNext;

    const response = await api.products.fetch(undefined, {
        category_id: categoryId,
        show_id: showId,
        cursor: initialCursor || undefined,
    });

    const nextCursor = response.next
        ? extractQueryParam(response.next, 'cursor')
        : null;

    dispatch({
        type: 'intermission/loaded-products',
        categoryId,
        showId,
        products: response.results,
        cursorNext: nextCursor,
        reset,
    });
};

export const startLoadProducts = (): AppThunk => async (dispatch: any) => {
    dispatch({ type: 'intermission/start-load-products' });
};

export const fetchCategories = (
    showId: ShowID,
    categoryId: CategoryID,
): AppThunk => async (dispatch: any) => {
    const categories = await api.categories.fetch();

    dispatch({
        type: 'intermission/loaded-categories',
        categories,
    });

    if (categories.length > 0) {
        const first = categories[0];

        dispatch(fetchProductsForCategory(categoryId || first.id, showId));
    }
};

export const selectCategories = (state: AppState): Array<Category> => {
    return state.shop.categories.map(
        (id: CategoryID): Category => state.shop.category[id],
    );
};

export const selectIsCategoriesStartLoad = (state: AppState): boolean =>
    state.shop.isCategoriesStartLoad;

export const selectProductsForCategory = (
    categoryId: CategoryID | null,
    state: AppState,
): Array<Product> => {
    if (!categoryId) {
        return [];
    }

    const category = state.shop.productsByCategory[categoryId];
    const items = category?.items || [];

    return items.map((id: ProductID): Product => state.shop.products[id]);
};

export const selectIsProductsStartLoad = (state: AppState): boolean =>
    state.shop.isProductsStartLoad;

export const selectCursorNext = (
    categoryId: CategoryID | null,
    state: AppState,
): string | null => {
    const category = state.shop.productsByCategory[categoryId];
    return category?.cursorNext || null;
};
