import React, { useState } from 'react';
import Modal from 'tg-modal';

import { gettext } from '../../utils/text';
import GallerySlider from './GallerySlider';
import sort from './utils';

const StagingGallery = ({ data }) => {
    const [open, setOpen] = useState(false);
    const [initialSlide, setSlide] = useState(0);
    const translatedText = gettext('Gallery');

    const toggleOpen = ev => {
        setOpen(true);
        if (ev) {
            ev.preventDefault();
        }
    };

    const toggleClose = ev => {
        setOpen(false);
        if (ev) {
            ev.preventDefault();
        }
    };

    const images = data.images.sort(sort);
    const imgElements = images.map((image, idx) => (
        <div
            className="gallery__container__column"
            key={`order-${image.file.pk}`}
            onClick={() => {
                setSlide(idx);
                toggleOpen();
            }}
            onKeyDown={() => {
                setSlide(idx);
                toggleOpen();
            }}
        >
            <img
                alt={`gallery item number ${idx}`}
                src={image.file.thumbnail}
            />
        </div>
    ));

    return [
        <h3 className="mb-5" key="headline">
            {translatedText}
        </h3>,
        <div className="row gallery__container__row" key="thumbs">
            {imgElements}
        </div>,
        <Modal
            isOpen={open}
            autoWrap={false}
            onCancel={() => toggleClose()}
            dialogClassName="gallery-modal"
            key="mdl"
        >
            <button
                className="tg-modal-close"
                aria-label="Close"
                type="button"
                onClick={() => toggleClose()}
            />
            <GallerySlider images={images} initialSlide={initialSlide} />
        </Modal>,
    ];
};

export default StagingGallery;
