import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { gettext } from '../utils/text';

// For some very odd reason `makemessages` needs parentheses here which prettier wants to remove
// prettier-ignore
const getSuggestionValue = suggestion => (suggestion.name);
// prettier-ignore
const renderSuggestion = suggestion => (<span>{suggestion.name}</span>);

const SearchForm = () => {
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const onChange = (event, { newValue }) => {
        setValue(newValue);
    };

    const onSuggestionsFetchRequested = ({ value: searchQuery }) => {
        // eslint-disable-line no-shadow
        fetch(`autocomplete?q=${searchQuery}`)
            .then(response => response.json())
            .then(data => setSuggestions(data.results));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const onSuggestionSelected = (e, { suggestion }) => {
        window.location.href = suggestion.link;
    };

    const inputProps = {
        placeholder: gettext('Search...'),
        value,
        onChange,
        className: 'form-control',
        name: 'q',
    };

    return (
        <>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                onSuggestionSelected={onSuggestionSelected}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
            />
            <button type="submit" className="btn btn-primary btn--search" />
        </>
    );
};

export default SearchForm;
