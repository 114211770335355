/* global imagesData */
import React from 'react';
import Slider from 'react-slick';
import Image from './Image';

class ImageCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            containerWidth: null,
            images: null,
        };
    }

    componentDidMount() {
        // fetch images data
        this.getData();
        // set up resizing handler and fetch initial dimensions
        window.addEventListener('resize', () => this.onresize());
        this.onresize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.onresize());
    }

    onresize() {
        this.setState({
            containerWidth: document.querySelector('.container__images')
                .offsetWidth,
        });
    }

    // eslint-disable-next-line class-methods-use-this
    getData() {
        // this fn will make an API call and setState(images)
        this.setState({ images: imagesData });
    }

    transformData(startIndex) {
        // this fn will make an API call
        let i = startIndex || 0;
        return this.state.images.map(item => {
            i += 1;
            return <Image key={i} {...item} />;
        });
    }

    render() {
        const imageWidth = 375;
        const { images } = this.state;
        if (!images || !images.length) {
            return null;
        }
        const { containerWidth } = this.state;
        let innerClassName = '__images';
        let result;
        let style;
        const width = images.length * imageWidth;
        if (containerWidth > width) {
            // no need for carousel if all can fit width
            result = this.transformData(images);
            innerClassName = `${innerClassName} __centered`;
            style = { width };
        } else {
            const minElements = 1;
            const sliderSettings = {
                infinite: true,
                speed: 500,
                slidesToShow: minElements,
                variableWidth: true,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 2000,
            };
            result = this.transformData(images);
            while (result.length < minElements) {
                // fill the images list so that it contains the minimum number of elements
                result = result.concat(this.transformData(images.length));
            }
            result = <Slider {...sliderSettings}>{result}</Slider>;
        }
        // eslint-disable-next-line consistent-return
        return (
            <>
                <div className={innerClassName} style={style}>
                    {result}
                </div>
            </>
        );
    }
}
export default ImageCarousel;
