import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const setCookie = (name, value, maxAge) => {
    document.cookie = `${name}=${value}; max-age=${maxAge}; path=/`;
};

const getCookie = (name, value) => {
    return document.cookie.includes(`${name}=${value}`);
};

const AlertWindow = ({ data }) => {
    const [isAlertOpen, setIsAlertOpen] = useState(false);

    useEffect(() => {
        if (!getCookie('alertPopup', false)) {
            setIsAlertOpen(true);
        }
    }, []);

    const closeWindow = () => {
        setCookie('alertPopup', 'false', 1800);
        setIsAlertOpen(false);
    };

    return (
        <>
            {isAlertOpen ? (
                <div className="alert-window">
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                    <span
                        className="close-button"
                        onClick={() => {
                            closeWindow();
                        }}
                    >
                        &times;
                    </span>
                    <h4>{data.title !== 'None' ? data.title : ''}</h4>
                    <br />
                    <p>{data.message}</p>
                    {data.link && (
                        <button
                            className="btn btn-primary confirm-button"
                            aria-label="open link"
                            type="button"
                            onClick={() => {
                                closeWindow();
                                window.open(data.link, '_blank');
                            }}
                        >
                            {data.button}
                        </button>
                    )}
                </div>
            ) : (
                <div />
            )}
        </>
    );
};

AlertWindow.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        button: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
    }).isRequired,
};

export default AlertWindow;
