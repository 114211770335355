import 'react-app-polyfill/ie11';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';

import SeriesSlider from 'components/blog/SeriesSlider';
import PrettyQuote from 'components/blog/PrettyQuote';

import loadMore from 'utils/load_more';

import rootReducer from './reducers';
import configureStore from './store';
import ActorsCarousel from './components/ActorsCarousel';
import ImageCarousel from './components/ImageCarousel';
import ManagementContainer from './components/Management/ManagementContainer';
import RestaurantMenu from './components/RestaurantMenu';
import { BlogGalleryLink, StagingGallery } from './components/Gallery';
import HomePage from './components/HomePage';
import SearchForm from './components/SearchForm';
import SubscribeForm from './components/blog/SubscribeForm';
import AlertWindow from './components/AlertWindow';

// Configure Sentry in deployed envs
if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: DJ_CONST.SENTRY_DSN,
        environment: DJ_CONST.SENTRY_ENVIRONMENT,
    });

    // handle rejected promises
    window.addEventListener('unhandledrejection', evt => {
        Sentry.captureException(evt.reason);
    });
    // If we have authenticated user, pass its data on to Sentry
    if (DJ_CONST.user) {
        Sentry.configureScope(scope => {
            scope.setUser({
                id: DJ_CONST.user.id,
                email: DJ_CONST.user.email,
            });
            Sentry.setContext('name', DJ_CONST.user.name);
        });
    }
}

function quotes() {
    const article = document.getElementById('post');
    if (!article) {
        return;
    }

    const tweetQuotes = article.getElementsByClassName('tweet-quote');
    if (tweetQuotes.length === 0) {
        return;
    }

    Array.prototype.forEach.call(tweetQuotes, quote => {
        const quoteText = quote.innerText;
        ReactDOM.render(<PrettyQuote content={quoteText} />, quote);
    });
}

function makeTablesScrollable() {
    function wrap(el, wrapper) {
        if (el.parentNode !== null) {
            el.parentNode.insertBefore(wrapper, el);
            wrapper.appendChild(el);
        }
    }

    // get all the tables within CMS plugin
    const tables = document.querySelectorAll('div.cms-content > table');
    if (tables !== null) {
        // wrap tables with a scrollable wrapper
        for (let i = 0; i < tables.length; i++) {
            // create a scrollable wrapper
            const tableWrapper = document.createElement('div');
            tableWrapper.style.cssText = 'overflow-x:auto';
            tableWrapper.id += `table-wrapper-${i}`;
            wrap(tables[i], tableWrapper);
        }
    }
}

function addLoadMoreListener() {
    document.addEventListener('click', e => {
        if (e.target.id === 'load-more') {
            loadMore(e.target);
        }
    });
}

function addSeriesSlider() {
    const slider = document.getElementById('series-slider-area');
    if (!slider) {
        return;
    }

    ReactDOM.render(<SeriesSlider data={slider.dataset.children} />, slider);
}

function initSubscribeForm() {
    const subscribeElem = document.getElementById('newsletter-section-footer');
    if (!subscribeElem) {
        return;
    }

    ReactDOM.render(
        <SubscribeForm siteKey={DJ_CONST.RECAPTCHA_PUBLIC_SITE_KEY} />,
        subscribeElem,
    );
}

function initBlog() {
    quotes();
    addLoadMoreListener();
    addSeriesSlider();
}

// Create Redux store
const store = configureStore(rootReducer);

function initHome(artistsData) {
    const elem = document.querySelector('.container__actors');
    if (elem) {
        ReactDOM.render(<ActorsCarousel artists={artistsData} />, elem);
    }

    const wrap = document.getElementById('showsselector');
    if (wrap) {
        ReactDOM.render(
            <Provider store={store}>
                <HomePage />
            </Provider>,
            wrap,
        );
    }

    const searchForm = document.getElementById('search-input-form');
    if (searchForm) {
        ReactDOM.render(<SearchForm />, searchForm);
    }
    const navSearchForm = document.getElementById('nav-search-input-form');
    if (searchForm) {
        ReactDOM.render(<SearchForm />, navSearchForm);
    }
}

function initRestaurant() {
    const imgElem = document.querySelector('.container__images');
    if (!imgElem) {
        return;
    }

    ReactDOM.render(<ImageCarousel />, imgElem);
}

function initManagement() {
    const elem = document.getElementById('management-container');

    if (!elem) {
        return;
    }

    ReactDOM.render(<ManagementContainer />, elem);
}

function initRestaurantMenu() {
    const elem = document.querySelector('.menu');

    if (!elem) {
        return;
    }

    ReactDOM.render(
        <Provider store={store}>
            <RestaurantMenu key="menu" />
        </Provider>,
        elem,
    );
}

function initBlogGallery() {
    const elem = document.querySelector('.masthead__gallery-link');

    if (!elem || !elem.dataset.gallery) {
        return;
    }

    let data;

    try {
        data = JSON.parse(elem.dataset.gallery);
    } catch (e) {
        return;
    }

    ReactDOM.render(<BlogGalleryLink data={data} />, elem);
}

function initGallery() {
    const elem = document.getElementById('gallery');

    if (!elem || !elem.dataset.gallery) {
        return;
    }

    let data;

    try {
        data = JSON.parse(elem.dataset.gallery);
    } catch (e) {
        return;
    }

    if (data) {
        ReactDOM.render(<StagingGallery data={data} />, elem);
    }
}

function runOnScroll() {
    // Header opacity stuff
    const fixedHeader = document.querySelector('.nav-wrap');

    if (document.documentElement.scrollTop > 108) {
        fixedHeader.classList.add('sticky');
    } else {
        fixedHeader.classList.remove('sticky');
    }
}

function initStickyMenu() {
    window.addEventListener('scroll', runOnScroll);
}

function initAlert() {
    const elem = document.getElementById('alert_window');

    if (!elem || !elem.dataset) {
        return;
    }
    let data;

    try {
        data = elem.dataset;
    } catch (e) {
        return;
    }
    if (data) {
        ReactDOM.render(<AlertWindow data={data} />, elem);
    }
}

export {
    initHome,
    initBlog,
    initRestaurant,
    initManagement,
    initRestaurantMenu,
    initBlogGallery,
    initGallery,
    initSubscribeForm,
    makeTablesScrollable,
    initStickyMenu,
    initAlert,
}; // eslint-disable-line import/prefer-default-export
