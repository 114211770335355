import PropTypes from 'prop-types';

const ImageShape = PropTypes.shape({
    thumbnail: PropTypes.string,
    file: PropTypes.string,
});

// eslint-disable-next-line import/prefer-default-export
export const ImageOrderShape = PropTypes.shape({
    file: ImageShape,
    order: PropTypes.number,
});
