import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
    startLoadProducts,
    fetchProductsForCategory,
    selectProductsForCategory,
    selectCursorNext,
} from '../../ducks/shop.ts';
import { gettext } from '../../utils/text';
import { selectors as cartSelectors, addToCart } from '../../ducks/cart';

const MenuItems = React.forwardRef(({ activeCategory, selectedShow }, ref) => {
    const [items, cursorNext] = useSelector(state => [
        selectProductsForCategory(activeCategory, state),
        selectCursorNext(activeCategory, state),
    ]);
    const cart = useSelector(cartSelectors.cart);

    const getPreviousCartValues = () => {
        const previousCartValues = {};
        if (cart && cart.lines.length) {
            for (let i = 0; i < cart.lines.length; i++) {
                previousCartValues[cart.lines[i].product.id] =
                    cart.lines[i].quantity;
            }
        }

        return previousCartValues;
    };

    const [cartValues, setCartValues] = useState(getPreviousCartValues);

    useEffect(() => {
        setCartValues(getPreviousCartValues());
    }, [cart]);

    const dispatch = useDispatch();

    const onLoadMore = useCallback(() => {
        dispatch(startLoadProducts());
        dispatch(fetchProductsForCategory(activeCategory, selectedShow));
    }, [dispatch, cursorNext, activeCategory, selectedShow]);

    const increaseQuantity = itemId => {
        setCartValues({
            ...cartValues,
            [itemId]: (cartValues[itemId] || 0) + 1,
        });
    };

    const decreaseQuantity = itemId => {
        if (cartValues[itemId]) {
            setCartValues({ ...cartValues, [itemId]: cartValues[itemId] - 1 });
        }
    };

    return (
        <div ref={ref}>
            {items.map(item => (
                <div
                    className="menu__right-menu__tab"
                    style={{
                        backgroundImage: `url(${item.thumbnail})`,
                    }}
                    key={item.id}
                >
                    <div className="menu__right-menu__tab__text">
                        <div className="d-flex justify-content-between w-100 pt-3">
                            <p
                                className="menu__description"
                                dangerouslySetInnerHTML={{
                                    __html: item.name,
                                }}
                            />
                            <p className="ml-2 menu__right-menu__tab__price--top">
                                {item.price.formatted.gross}
                            </p>
                        </div>
                        <p className="menu__right-menu__tab__price--bottom">
                            {item.price.formatted.gross}
                        </p>
                        <div className="cart">
                            <div className="d-flex py-3">
                                <span // eslint-disable-line jsx-a11y/click-events-have-key-events
                                    className="cart__decrease"
                                    onClick={() => decreaseQuantity(item.id)}
                                >
                                    -
                                </span>
                                <span className="cart__number">
                                    {cartValues[item.id] || 0}
                                </span>
                                <span // eslint-disable-line jsx-a11y/click-events-have-key-events
                                    className="cart__increase"
                                    onClick={() => increaseQuantity(item.id)}
                                >
                                    +
                                </span>
                            </div>
                            <button
                                className="btn btn-primary btn--arrow"
                                onClick={() =>
                                    dispatch(
                                        addToCart({
                                            product: item.id,
                                            quantity: cartValues[item.id],
                                        }),
                                    )
                                }
                                disabled={!cartValues[item.id]}
                                type="button"
                            >
                                {gettext('Add to cart')}
                            </button>
                        </div>
                    </div>
                </div>
            ))}

            {cursorNext ? (
                <button
                    onClick={onLoadMore}
                    className="btn btn-link btn-sm"
                    type="button"
                >
                    {gettext('Load more')}
                </button>
            ) : null}
        </div>
    );
});

MenuItems.propTypes = {
    activeCategory: PropTypes.number,
    selectedShow: PropTypes.number,
};
export default MenuItems;
