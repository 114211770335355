import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prefer-stateless-function
class Image extends React.Component {
    static propTypes = {
        backgroundImage: PropTypes.string.isRequired,
    };

    render() {
        return (
            <div
                className="__image"
                style={{
                    backgroundImage: this.props.backgroundImage,
                }}
            />
        );
    }
}

export default Image;
