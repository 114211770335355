import { combineReducers } from 'redux';

import title from './ducks/title';
import shows from './ducks/shows';
import { shopReducer } from './ducks/shop.ts';
import cart from './ducks/cart';

const rootReducer = combineReducers({
    title,
    shows,
    shop: shopReducer,
    cart,
});

export default rootReducer;
