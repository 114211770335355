import React from 'react';
import ManagerCard from './ManagerCard';

// TODO replace with call to backend API to fetch the info
const sampleDescription =
    '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ' +
    'incididunt ut labore et dolore magna aliqua. Maecenas pharetra convallis posuere morbi leo urna molestie.' +
    'Neque volutpat ac tincidunt vitae semper. Dolor magna eget est lorem ipsum. Elit pellentesque habitant morbi ' +
    'tristique senectus. Interdum varius sit amet mattis vulputate. Orci phasellus egestas tellus rutrum tellus ' +
    'pellentesque. Massa eget egestas purus viverra accumsan in. Ultrices mi tempus imperdiet nulla malesuada ' +
    'pellentesque elit. Odio aenean sed adipiscing diam. Facilisi nullam vehicula ipsum a arcu. Ornare arcu odio ' +
    'ut sem.\n</p>' +
    '<p>Urna et pharetra pharetra massa massa ultricies mi. Vestibulum mattis ullamcorper velit sed ullamcorper ' +
    'tincidunt ornare massa. At tellus at urna condimentum. Vitae turpis massa sed elementum tempus egestas sed. ' +
    'Varius sit amet mattis vulputate. At risus viverra adipiscing at in tellus integer. Gravida arcu ac tortor ' +
    'dignissim convallis aenean. Vitae congue mauris rhoncus aenean vel elit scelerisque. Nunc id cursus metus ' +
    'aliquam. Tincidunt augue interdum velit euismod in pellentesque massa. Facilisi nullam vehicula ipsum a arcu ' +
    'cursus. Massa ultricies mi quis hendrerit dolor magna. Tristique sollicitudin nibh sit amet commodo nulla ' +
    'facilisi. Suspendisse interdum consectetur libero id faucibus nisl. Tristique senectus et netus et malesuada ' +
    'fames ac turpis egestas.\n</p>' +
    '<p>\nLeo urna molestie at elementum eu facilisis sed. Tortor at risus viverra adipiscing at in tellus integer ' +
    'feugiat. Nullam eget felis eget nunc lobortis. Purus gravida quis blandit turpis cursus in hac habitasse ' +
    'platea. Blandit cursus risus at ultrices. Nullam vehicula ipsum a arcu cursus vitae congue mauris rhoncus. ' +
    'Aenean euismod elementum nisi quis eleifend quam adipiscing. Vitae tempus quam pellentesque nec. Augue ut ' +
    'lectus arcu bibendum. Et odio pellentesque diam volutpat commodo sed egestas egestas. Libero enim sed faucibus ' +
    'turpis in. Mi eget mauris pharetra et. Mi bibendum neque egestas congue quisque egestas diam in arcu. Vitae ' +
    'tempus quam pellentesque nec nam aliquam sem et tortor. Sed adipiscing diam donec adipiscing tristique risus ' +
    'nec feugiat. Auctor eu augue ut lectus arcu bibendum at varius vel. Laoreet sit amet cursus sit amet dictum sit ' +
    'amet justo. Libero enim sed faucibus turpis. Nunc consequat interdum varius sit amet mattis vulputate enim.\n' +
    'Ac odio tempor orci dapibus ultrices. Aenean euismod elementum nisi quis. Elementum nibh tellus molestie nunc ' +
    'non blandit massa enim. Nam aliquam sem et tortor consequat. Cum sociis natoque penatibus et magnis. Mi sit ' +
    'mauris commodo quis imperdiet massa tincidunt. Quis imperdiet massa tincidunt nunc. Arcu non odio euismod ' +
    'lacinia at quis. Vitae tempus quam pellentesque nec nam aliquam. Erat pellentesque adipiscing commodo elit. ' +
    'Ornare aenean euismod elementum nisi quis eleifend quam adipiscing vitae.</p>' +
    '<b>CV</b>' +
    '<ul><li>Sünniaeg: 12. mai 1960</li><li>Perekonnaseis: abielus, kolm last</li></ul>' +
    '<b>Hariduskäik</b>' +
    '<ul><li>1979-1985 Tallinna Riiklik Konservatoorium</li><li>1993-1995 Tallinna muusikakeskkool, klarnet</li></ul>' +
    '<b>Ametikäik</b>' +
    '<ul><li>1009- Rahvusooper Estonia peadirektor</li><li>2000-2009 Eesti Kontserdi direktor</li>' +
    '<li>2004-2006 Vanemuise teatrijuht</li><li>2003-2004 Vanemuise teatrijuhi kt</li>' +
    '<li>1999-2000 Eesti Kontserdi direktori kt</li></ul>' +
    '<b>Hobid</b> <ul><li>Arsise kellade ansambel</li></ul>';

const managementList = [
    {
        id: 1,
        name: 'Aivar Mäe',
        role: 'peadirektor',
        image: '/static/images/placeholders/aivar_mae.png',
        description: sampleDescription,
    },
    {
        id: 2,
        name: 'Vello Pähn',
        role: 'loominguline juht',
        image: '/static/images/placeholders/vello_pahn.png',
        description: sampleDescription,
    },
    {
        id: 3,
        name: 'Toomas Edur',
        role: 'kunstiline juht',
        image: '/static/images/placeholders/toomas_edur.png',
        description: sampleDescription,
    },
];

const ManagementContainer = () => (
    <div className="managers">
        {managementList.map(manager => (
            <ManagerCard key={manager.id} manager={manager} />
        ))}
    </div>
);

export default ManagementContainer;
