import React from 'react';
import { components } from 'react-select';

const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7">
            <path
                fill="none"
                stroke="#e4002b"
                strokeMiterlimit="50"
                d="M.953 1l4.913 5 5.087-5"
            />
        </svg>
    </components.DropdownIndicator>
);

export default DropdownIndicator;
