import { createRouter } from 'tg-resources';
import { FetchResource } from '@tg-resources/fetch';
import reverseUrl from './urls';

/**
 * Get csrf token
 */
export function getCsrfToken() {
    if (typeof window !== 'undefined') {
        const name = 'csrftoken';

        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = `${cookies[i]}`.trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === `${name}=`) {
                    return decodeURIComponent(
                        cookie.substring(name.length + 1),
                    );
                }
            }
        }
    }

    return '';
}

const api = createRouter(
    {
        newsletter: reverseUrl('newsletter-signup'),
        shows: reverseUrl('show-list'),
        categories: reverseUrl('category-list'),
        products: reverseUrl('product-list'),
        loadCart: reverseUrl('cart-list'),
        addToCart: reverseUrl('cart-add'),
        removeFromCart: reverseUrl('cart-remove'),
        checkoutCart: reverseUrl('cart-checkout'),
        filterOptions: reverseUrl('show-filter-options'),
        intermissionShows: reverseUrl('show-intermission-shows'),
    },
    {
        headers: () => ({
            'X-CSRFToken': getCsrfToken(),
            Accept: 'application/json',
        }),
        withCredentials: true,
    },
    FetchResource,
);

export default api;
