import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';

export const ErrorComponent = ({ name, className }) => (
    <ErrorMessage
        component="div"
        name={name}
        className={`${className} text-small text-danger`}
    />
);

ErrorComponent.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
};

ErrorComponent.defaultProps = {
    className: null,
};
