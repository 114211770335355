// Actions
import { parse, addDays, addMonths } from '../utils/date';
import api from '../utils/router.ts';
import { gettext } from '../utils/text';

const SET_SHOWS = 'SHOWS/SET_SHOWS';
const SET_INTERMISSION_SHOWS = 'SHOWS/SET_INTERMISSION_SHOWS';
const SET_FILTERS = 'SHOWS/SET_FILTERS';
const SET_FILTER_OPTIONS = 'SHOWS/SET_FILTER_OPTIONS';
const SET_NEXT_PAGE = 'SHOWS/SET_NEXT_PAGE';

// Initial state for reducer
const initialState = {
    shows: [],
    intermissionShows: [],
    initialLoading: true,
    nextLoading: true,
    filters: {
        staging: { value: 'all', label: gettext('All stagings') },
        period: { value: 1, label: gettext('Upcoming shows') },
        stagingType: { value: 'all', label: gettext('All genres') },
        upcomingShowsPageNumber: 1,
    },
    filterOptions: {
        stagings: [],
        periods: [],
        stagingTypes: [],
    },
    nextPage: null,
};

const parseDates = shows =>
    shows.map(show => {
        const s = { ...show };
        const date = parse(
            show.time.split('T').join(' '),
            'yyyy-MM-dd HH:mm:ssxxx',
            new Date(),
        );
        const hours = show.time.split('T')[1].substring(0, 5);
        s.time = { date, hours };
        return s;
    });

// Reducer(s)
export default function showsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SHOWS: {
            const prevPageShows = state.shows;
            let parsedShows = parseDates(action.shows);
            // If upcoming shows are selected then append the newly fetched shows
            // to the previous state shows to list all shows.
            // Variable `reset` sets if shows should be loaded from the start
            if (state.filters.period.value === 1 && !action.reset) {
                parsedShows = [...prevPageShows, ...parsedShows];
            }
            return {
                ...state,
                initialLoading: false,
                nextLoading: false,
                shows: parsedShows,
            };
        }
        case SET_INTERMISSION_SHOWS:
            return {
                ...state,
                intermissionShows: parseDates(action.intermissionShows),
            };
        case SET_FILTERS: {
            const filters = { ...state.filters, ...action.updatedFilter };
            const areFiltersChanged =
                state.filters.staging.value !== filters.staging.value ||
                state.filters.period.value !== filters.period.value ||
                state.filters.stagingType.value !== filters.stagingType.value;
            return {
                ...state,
                initialLoading: areFiltersChanged,
                nextLoading:
                    state.filters.upcomingShowsPageNumber !==
                    filters.upcomingShowsPageNumber,
                filters,
            };
        }
        case SET_FILTER_OPTIONS:
            return {
                ...state,
                filterOptions: action.options,
            };
        case SET_NEXT_PAGE:
            return {
                ...state,
                nextPage: action.next,
            };
        default:
            return state;
    }
}

const setShows = (shows, reset) => ({ type: SET_SHOWS, shows, reset });
const setNextPage = next => ({ type: SET_NEXT_PAGE, next });
const setIntermissionShows = intermissionShows => ({
    type: SET_INTERMISSION_SHOWS,
    intermissionShows,
});
const setFilterOptions = options => ({ type: SET_FILTER_OPTIONS, options });
const updateFilters = updatedFilter => ({ type: SET_FILTERS, updatedFilter });

// Formats filters for submitting to backend as query parameters
const formatFilters = filters => {
    const formattedFilters = {};

    if (filters === null) {
        return formattedFilters;
    }

    Object.keys(filters).forEach(filter => {
        if (filters[filter].value !== 'all') {
            formattedFilters[filter] = filters[filter].value;
        }
    });

    const now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    const missingDays = 7 - (now.getDay() === 0 ? 7 : now.getDay());

    const periodsById = {
        2: {
            min: now,
            max: addDays(now, missingDays + 1),
        },
        3: {
            min: addDays(now, missingDays + 1),
            max: addDays(now, missingDays + 8),
        },
    };

    if (filters.period.value === 1) {
        formattedFilters.size = 15; // limit result to first 15 shows
        formattedFilters.page = filters.upcomingShowsPageNumber; // page number to get
    } else if (filters.period.value === 2 || filters.period.value === 3) {
        const period = periodsById[filters.period.value];
        formattedFilters.time__gte = period.min;
        formattedFilters.time__lte = period.max;
    } else {
        const startDate = new Date(filters.period.value);
        const endDate = addMonths(startDate, 1);
        formattedFilters.time__gte = startDate;
        formattedFilters.time__lte = endDate;
    }

    return formattedFilters;
};

export const getShows = (filters, reset) => dispatch => {
    api.shows.fetch(undefined, formatFilters(filters)).then(shows => {
        dispatch(setNextPage(shows.next));
        dispatch(setShows(shows.results, reset));
    });
};

export const getIntermissionOrderShows = () => dispatch => {
    api.intermissionShows.fetch().then(intermissionShows => {
        dispatch(setIntermissionShows(intermissionShows));
    });
};

export const getFilterOptions = filters => dispatch => {
    api.filterOptions.fetch(undefined, formatFilters(filters)).then(options => {
        dispatch(setFilterOptions(options));
    });
};

export const setFilter = (key, value) => dispatch => {
    const updatedFilter = {};
    updatedFilter[key] = value;
    dispatch(updateFilters(updatedFilter));
};

// helpful stuff
const getStagings = state => {
    const stagingList = state.shows.filterOptions.stagings
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(staging => {
            return { value: staging.id, label: staging.name };
        });

    return [{ value: 'all', label: gettext('All stagings') }].concat(
        stagingList,
    );
};

const getPeriods = state => {
    const defaultOptions = [
        { value: 1, label: gettext('Upcoming shows') },
        { value: 2, label: gettext('Current week') },
        { value: 3, label: gettext('Next week') },
    ];

    return defaultOptions.concat(state.shows.filterOptions.periods);
};

const getStagingTypes = state => {
    return [initialState.filters.stagingType].concat(
        Object.values(state.shows.filterOptions.stagingTypes).map(
            stagingType => ({
                value: stagingType.id,
                label: stagingType.name,
            }),
        ),
    );
};

export const selectors = {
    shows: state => state.shows.shows,
    filters: state => state.shows.filters,
    intermissionShows: state =>
        state.shows.intermissionShows.map(show => ({
            value: show.id,
            label: show.full_name,
            stagingId: show.staging_id,
        })),
    filterOptions: state => state.shows.filterOptions,
    stagings_options: state => getStagings(state),
    period_options: state => getPeriods(state),
    staging_type_options: state => getStagingTypes(state),
    staging: state => state.shows.filters.staging,
    stagingType: state => state.shows.filters.stagingType,
    getSelectedFilter: (state, filter) => state.shows.filters[filter],
    nextPage: state => state.shows.nextPage,
};
