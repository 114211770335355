import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import { gettext } from '../../utils/text';
import api from '../../utils/router.ts';

const SubscribeForm = ({ siteKey }) => {
    const [sent, setSent] = useState(false);
    const [email, setEmail] = useState('');
    const [language, setLanguage] = useState('et');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [submitMessage, setSubmitMessage] = useState('');
    const [captchaResponse, setCaptchaResponse] = useState('');

    const recaptchaRef = React.createRef();

    const handleCaptcha = key => {
        setCaptchaResponse(key);
    };

    const resetCaptcha = () => {
        recaptchaRef.current.reset();
    };

    const onChange = e => {
        setEmail(e.target.value);
        setIsValidEmail(e.target.checkValidity());
        // Reset recaptcha after submitting the subscription form
        if (sent) {
            resetCaptcha();
        }
        setSent(false);
    };

    const onSetLanguage = e => {
        setLanguage(e.target.value);
    };

    const handleSubmit = e => {
        const isValid = e.target.checkValidity();
        e.preventDefault();
        if (isValid) {
            const onLoad = result => {
                setSubmitMessage(result.message);
            };

            const onError = result => {
                // response should contain some message (and not html script with captcha sent if there was no captcha
                // sent on submit)
                if (
                    result.responseText !== '' &&
                    result.responseText.charAt(0) !== '<'
                ) {
                    setSubmitMessage(JSON.parse(result.responseText).message);
                } else {
                    setSubmitMessage(
                        gettext(
                            'Something went wrong. Please try again later.',
                        ),
                    );
                }
            };

            const payload = {
                email,
                language,
                'g-recaptcha-response': captchaResponse,
            };

            api.newsletter
                .post(null, payload)
                .then(onLoad)
                .catch(onError);
            setSent(true);
        }
    };

    return (
        <div>
            <h3 className="mb-0">{gettext('Subscribe ')}</h3>
            <h3>{gettext('to newsletter')}</h3>
            <p>
                {gettext(
                    'Sign up for monthly Estonian national opera theatre newsletter and be up to date ' +
                        'with latest news and offers.',
                )}
            </p>
            <form onSubmit={e => handleSubmit(e)}>
                <input
                    className="form-control"
                    type="email"
                    name="email"
                    onChange={e => onChange(e)}
                    value={email}
                    placeholder="E-mail"
                    required
                />
                <ReCAPTCHA
                    className="mt-2"
                    onChange={e => handleCaptcha(e)}
                    ref={recaptchaRef}
                    sitekey={siteKey}
                    style={{ display: `${isValidEmail ? 'block' : 'none'}` }}
                />
                {sent && <div className="mt-1">{submitMessage}</div>}
                <div className="button-wrp position-absolute">
                    <button
                        className="btn btn-primary btn--arrow position-relative"
                        value="et"
                        type="submit"
                        onClick={onSetLanguage}
                        disabled={!email || !isValidEmail}
                        formNoValidate
                    >
                        {gettext('Sign up for our newsletter')}
                    </button>
                    <button
                        className="btn btn-primary btn--arrow position-relative mt-3"
                        value="ru"
                        type="submit"
                        onClick={onSetLanguage}
                        disabled={!email || !isValidEmail}
                        formNoValidate
                    >
                        Подписаться на рассылку новостей
                    </button>
                </div>
            </form>
        </div>
    );
};

SubscribeForm.propTypes = {
    siteKey: PropTypes.string.isRequired,
};

export default SubscribeForm;
