import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'components/Loading';
import {
    fetchProductsForCategory,
    startLoadProducts,
    selectIsCategoriesStartLoad,
    selectCategories,
} from '../../ducks/shop.ts';

const MenuCategories = ({
    isOpen,
    isDocked,
    closeMenu,
    setHeaderTitle,
    headerRef,
    menuRef,
    activeCategory,
    setActiveCategory,
    selectedShow,
}) => {
    const [menuWidth, setMenuWidth] = useState(null);
    const dispatch = useDispatch();

    const onResizeMenu = () => {
        const windowSize = window.innerWidth;
        if (windowSize < 768) {
            if (headerRef.current) {
                setMenuWidth(headerRef.current.clientWidth + 2);
            }
        } else {
            setMenuWidth('auto');
        }
    };

    useEffect(() => {
        window.addEventListener('resize', onResizeMenu);
        window.addEventListener('load', onResizeMenu);
    });

    const categories = useSelector(selectCategories);

    useEffect(() => {
        if (activeCategory === null) {
            if (categories.length > 0) {
                setActiveCategory(categories[0].id);
            }
        }
    }, [activeCategory, categories, setActiveCategory]);

    const selectAndHideMenu = (categoryId, title) => {
        setActiveCategory(categoryId);

        if (activeCategory !== categoryId) {
            dispatch(startLoadProducts());
            dispatch(fetchProductsForCategory(categoryId, selectedShow));
        }

        closeMenu();
        setHeaderTitle(title);
    };

    const isCategoriesStartLoad = useSelector(selectIsCategoriesStartLoad);

    return (
        <div
            className="menu__level-3"
            style={{ display: isOpen || isDocked ? 'block' : 'none' }}
            ref={menuRef}
        >
            {isCategoriesStartLoad ? (
                <Loading />
            ) : (
                categories.map((category, i) => {
                    const selected =
                        activeCategory === category.id ? 'selected' : '';

                    return (
                        <div
                            role="link"
                            className={`menu__level-3__row ${selected}`}
                            key={category.id}
                            tabIndex={i}
                            style={{ width: menuWidth }}
                            onClick={() =>
                                selectAndHideMenu(category.id, category.name)
                            }
                            onKeyPress={() =>
                                selectAndHideMenu(category.id, category.name)
                            }
                        >
                            {category.name}
                            <br />
                            <span className="menu__level-3__row__subtitle">
                                {category.description}
                            </span>
                        </div>
                    );
                })
            )}
        </div>
    );
};

MenuCategories.defaultProps = {
    activeCategory: null,
    selectedShow: null,
};

MenuCategories.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isDocked: PropTypes.bool.isRequired,
    closeMenu: PropTypes.func.isRequired,
    setHeaderTitle: PropTypes.func.isRequired,
    headerRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    menuRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    activeCategory: PropTypes.number,
    setActiveCategory: PropTypes.func.isRequired,
    selectedShow: PropTypes.number,
};

export default React.forwardRef((props, ref) => (
    <MenuCategories {...props} menuRef={ref} />
));
