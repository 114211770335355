import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';
import _format from 'date-fns/format';
import _formatDistance from 'date-fns/formatDistance';
import _formatDistanceStrict from 'date-fns/formatDistanceStrict';
import _formatRelative from 'date-fns/formatRelative';
import setDate from 'date-fns/setDate';
import parse from 'date-fns/parse';

import { et, en } from 'date-fns/locale';

const locales = { et, en };

export const getCurrentLocale = () => locales[DJ_CONST.languageCode];

const getFormatOptions = () => ({
    locale: getCurrentLocale(),
});

export { addDays, addMonths, addYears, setDate, parse };

export const format = (date, fmt, options) =>
    _format(date, fmt, {
        ...getFormatOptions(),
        ...options,
    });

export const formatDistance = (date, baseDate, options) =>
    _formatDistance(date, baseDate, {
        ...getFormatOptions(),
        ...options,
    });

export const formatDistanceStrict = (date, baseDate, options) =>
    _formatDistanceStrict(date, baseDate, {
        ...getFormatOptions(),
        ...options,
    });

export const formatRelative = (date, baseDate, options) =>
    _formatRelative(date, baseDate, {
        ...getFormatOptions(),
        ...options,
    });
